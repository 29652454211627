import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import logo from '../assests/stamp.svg'
import stamp from '../assests/stam.png'
import stamp2 from '../assests/sundlass.png'
import { FaLongArrowAltRight } from "react-icons/fa";
import axios from 'axios';

const Invoice2 = React.forwardRef((props, ref) => {
    const [data, setData] = useState([]);
    const [refe, setRef] = useState([]);
    const [amountInWords, setAmountInWords] = useState('');
    const [displayterm, setDisplayterm] = useState('display-none');
    const [displayterm1, setDisplayterm1] = useState('display-none');
    const [displayterm2, setDisplayterm2] = useState('display-none');
    const [displayterm3, setDisplayterm3] = useState('display-none');
    const [amountInWords2, setAmountInWords2] = useState('');
    const [formattedpaymentdate, setFormattedPaymentDate] = useState('');
    const [formateddob, setFormattedDob] = useState('');
    const [todayDate, setTodayDate] = useState(new Date());
    const formattedDate = todayDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    function numberToWords(num) {
        const a = [
            '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
            'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
        ];
        const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
        const g = ['', 'thousand', 'million', 'billion'];

        function chunk(num) {
            return ('000' + num).substr(-3);
        }

        function inWords(num) {
            let str = '';
            const hundreds = Math.floor(num / 100);
            const tens = num % 100;
            if (hundreds) str += a[hundreds] + ' hundred ';
            if (tens < 20) str += a[tens];
            else str += b[Math.floor(tens / 10)] + ' ' + a[tens % 10];
            return str.trim();
        }

        if (num === 0) return 'zero';
        let result = '';
        let i = 0;
        while (num > 0) {
            const chunkValue = num % 1000;
            if (chunkValue) result = inWords(chunkValue) + ' ' + g[i] + ' ' + result;
            num = Math.floor(num / 1000);
            i++;
        }
        return result.trim();
    }
    const getinvoice = () => {
        axios.get(`https://invoice.pksinfotechpltd.com/backend/public/getinvoice/${props.id}`)
            .then((response) => {
                
                setData(response.data)
                console.log("invoice data", response.data);
                const dob = response.data.dob;
                const dateParts = dob.split('-');
                const formattedDob = `${dateParts[1]}/${dateParts[2]}`;
                // Store the formatted date in the ref
                var Reference = `${formattedDob}|${response.data.passport}|${formattedDate}`
                setRef(Reference);

                const amount = parseInt(response.data.amount, 10); // Convert to integer
                const amountInWords = numberToWords(amount); // Convert to words
                setAmountInWords(amountInWords);


                // Format paymentdate to DD-MM-YYYY
                const paymentDate = response.data.paymentdate;
                const datePartsPayment = paymentDate.split('-');
                const formattedPaymentDate = `${datePartsPayment[2]}-${datePartsPayment[1]}-${datePartsPayment[0]}`;
                setFormattedPaymentDate(formattedPaymentDate); // Assuming you're storing the formatted payment date

                // Format DOB to DD-MM-YYYY
                const DOB = response.data.dob;
                const Dobdate = DOB.split('-');
                const formatteddob = `${Dobdate[2]}-${Dobdate[1]}-${Dobdate[0]}`;
                setFormattedDob(formatteddob); // Assuming you're storing the formatted payment date

                if (response.data.pending_bal) {
                    const amount2 = parseInt(response.data.pending_bal, 10); // Convert to integer
                    const amountInWords2 = numberToWords(amount2); // Convert to words
                    setAmountInWords2(amountInWords2);
                }

                if (response.data.services === "Immigrant Visa") {
                    setDisplayterm1('display-block')
                } else if (response.data.services === "Student Visa") {
                    setDisplayterm2('display-block')
                } else if (response.data.services === "Tourist Visa") {
                    setDisplayterm3('display-block')
                } else {
                    setDisplayterm3('display-block')
                }

            }).catch((error) => {
                console.log(error);
            })
    }
    const getFormattedComments = (data) => {
        // Create an array of comment keys in the format `comment_1`, `comment_2`, etc.
        const commentKeys = ['comment_1', 'comment_2', 'comment_3', 'comment_4', 'comment_5'];

        // Map through the keys and filter out empty comments
        const formattedComments = commentKeys
            .map((key, index) => data[key] ? `${index + 1}. ${data[key]}` : null) // Add serial numbers
            .filter(comment => comment !== null) // Remove null entries
            .join(' '); // Join with a space

        return formattedComments;
    };
    useEffect(() => {
        getinvoice()
        setTodayDate(new Date());
    }, [props.id])
    return (
        <div ref={ref} id="targetLR">
            <Container className='border border-2 border-dark rounded-4 h-100  bg-white' style={{ minHeight: '100vh' }}>
                <div className="d-flex align-items-center justify-content-between me-4">
                    <img src={stamp2} alt="image" height={140} id="invoiceImage" className='me-5' />
                    <div className='text-center'>
                        {/* {JSON.stringify(props.id)} */}
                        <h3 className='ms-1 border-bottom-1 border-2 mb-1 border-blue fw-6'>
                            SUNDLASS CONSULTANTS<sup>&reg;</sup>
                        </h3>
                        <h6 className='ms-1 border-top border-2 border-dark border-blue pt-2 fw-6'>
                            A Complete Visa Counselling Services 
                        </h6>
                    </div>
                </div>
                <Row className='g-2 p-2'>
                    {/* //--------------------------------------------// */}
                    <Col md={8}>
                        <h6 className='text-dark pt-1 fw-6'>RECEIPT NO <span style={{marginLeft:"10px"}}>:</span><span className='ms-2 border-bottom border-dark text-dark'>{5000+parseInt(data.id)}</span></h6>
                    </Col>
              
                    <Col md={4}>
                        <h6 className='text-dark pt-1 fw-6' >Dated : <span className='ms-2 border-bottom border-dark text-dark'>{formattedDate}</span></h6>

                    </Col>
                    {/* //--------------------------------------------// */}
                    <Col md={8}>
                        <h6 className='text-dark pt-1 fw-6'>Reference No : <span className='ms-2 border-bottom border-dark text-dark'>{refe}</span></h6>
                    </Col>
                    <Col md={4}>
                        <h6 className='text-dark pt-1 fw-6'>Passport No : <span className='ms-2 border-bottom border-dark text-dark'>{data.passport}</span></h6>
                    </Col>
                    <Col md={8}>
                        <h6 className='text-dark pt-1 fw-6'>Received from Mr./Miss/Mrs : <span className='ms-2 border-bottom border-dark text-dark'>{data.name}</span></h6>
                    </Col>
                    <Col md={4}>
                        <h6 className='text-dark pt-1 fw-6'>DOB : <span className='ms-2 border-bottom border-dark text-dark'>{formateddob}</span></h6>
                    </Col>

                    {/* //--------------------------------------------// */}
                    <Col md={12}>
                        <h6 className='text-dark pt-1 fw-6'>S/o,W/o,D/o,Sh <span style={{ marginLeft: "16px" }}>:</span> <span className='ms-2 border-bottom border-dark text-dark'>{data.fname}</span></h6>
                    </Col>
                  


                    {/* //--------------------------------------------// */}
                    {(data.services !== 'Applying U.S Passport (New Born)' && data.services !== 'Applying U.S Passport (Lost)' && data.services !== 'Visa Conversion Within USA')
                        ? (
                            <>
                                <Col md={8}>
                                    <h6 className='text-dark pt-1 fw-6'>On Account Of <span style={{ marginLeft: "12px" }}>:</span> <span className='ms-2 border-bottom border-dark text-dark'>{data.services}</span></h6>
                                </Col>
                                {/* <Col md={2}>
                                  
                                </Col> */}
                                <Col md={4}>
                                    <h6 className='text-dark pt-1 fw-6'>Country : <span className='ms-2 border-bottom border-dark text-dark'>{data.country}</span></h6>
                                </Col>
                                {/* <Col md={}>
                                    
                                </Col> */}
                            </>
                        )
                        : (
                            <Col md={12}>
                                <h6 className='text-dark pt-1 fw-6'>On Account Of : <span className='ms-2 border-bottom border-dark text-dark'>{data.services}</span></h6>
                            </Col>
                        )
                    }

                    {(data.modeofpayment === 'Cash' || data.modeofpayment === 'UPI') ?
                        <>  <Col md={8}>
                            <h6 className='text-dark pt-1 fw-6'>Payment Mode <span className='ms-2'>:</span> <span className='ms-2 border-bottom border-dark text-dark'>{data.modeofpayment}</span></h6>
                        </Col>
                         {/* <Col md={2}>
                            
                        </Col> */}
                            <Col md={4}>
                                <h6 className='text-dark pt-1 fw-6'>Date <span style={{ marginLeft: "23px" }}>:</span> <span className='ms-2 border-bottom border-dark text-dark'>{formattedDate}</span></h6>
                            </Col>
                            {/* <Col md={3}>
                             
                            </Col> */}
                        </> : (data.modeofpayment === 'Cheque' || data.modeofpayment === 'Draft') ? (<>
                            <Col md={5}>
                                <h6 className='text-dark pt-1 fw-6'>Payment Mode : <span className='ms-2 border-bottom border-dark text-dark'>{data.modeofpayment}</span></h6>
                            </Col>
                            <Col md={3}>
                                <h6 className='text-dark pt-1 fw-6'> Date : <span className='ms-2 border-bottom border-dark text-dark'>{formattedpaymentdate}</span></h6>
                            </Col>
                            
                            <Col md={4}>
                                <h6 className='text-dark pt-1 fw-6'>Cheque/Draft No : <span className='ms-2 border-bottom border-dark text-dark'>{data.draftno}</span></h6>
                            </Col>
                                <Col md={12}>
                                    <h6 className='text-dark pt-1 fw-6'>Drawn At : <span className='ms-2 border-bottom border-dark text-dark'>{data.bank}</span></h6>
                            </Col > </>) : (data.modeofpayment === 'Bank Transfer' || data.modeofpayment === 'Net Banking') ? (<>
                                <Col md={6}>
                                    <h6 className='text-dark pt-1 fw-6'>Payment Mode : <span className='ms-2 border-bottom border-dark text-dark'>{data.modeofpayment}</span></h6>
                                </Col>
                                <Col md={6}>
                                    <h6 className='text-dark pt-1 fw-6'>Date   : <span className='ms-2 border-bottom border-dark text-dark'>{formattedpaymentdate}</span></h6>
                                </Col>
                                
                                <Col md={6}>
                                    <h6 className='text-dark pt-1 fw-6'>From Bank : <span className='ms-2 border-bottom border-dark text-dark'>{data.frombank}</span></h6>
                                </Col>
                                 </>) : ""
                    }

                    {data.pending_bal ? (<>
                        
                    <Col md={12}>
                            <h6 className='text-dark pt-1 fw-6'>Balance Payment , if any Rs : <span className='ms-2 me-2 border-bottom border-dark text-dark'>{data.pending_bal ? data.pending_bal : 'N/A'}</span> (Rupees : <span className='ms-2 border-bottom border-dark text-dark'>{amountInWords2}</span>)</h6>
                    </Col>
                    {/* <Col md={7}>
                            <h6 className='text-dark pt-1 fw-6'>(Rupees : <span className='ms-2 border-bottom border-dark text-dark'>{amountInWords2}</span>)</h6>
                    </Col> */}
                    </>) : <></>}
                    {/* {data.pending_bal ?
                    {(data.paidon === 'immediately' )
                        ? (
                            <>
                                <Col md={12}>
                                    <h6 className='text-dark pt-2 fw-6'>To Be Paid On : <span className='ms-2 border-bottom border-dark text-dark'>{data.painon}</span></h6>
                                </Col>
                            </>
                        )
                        : (<>
                            <Col md={6}>
                                <h6 className='text-dark pt-2 fw-6'>To Be Paid On : <span className='ms-2 border-bottom border-dark text-dark'>{data.painon}</span></h6>
                            </Col>
                            <Col md={6}>
                                <h6 className='text-dark pt-2 fw-6'>To Be Paid On Date : <span className='ms-2 border-bottom border-dark text-dark'>{data.painondate}</span></h6>
                            </Col>
                        </>
                        )
                    }:''
                    } */}
                    {data.pending_bal && (
                        (data.painon === 'immediately') ? (
                            <Col md={12}>
                                <h6 className='text-dark pt-1 fw-6'>
                                    To Be Paid On : <span className='ms-2 border-bottom border-dark text-dark'>{data.painon}</span>
                                </h6>
                            </Col>
                        ) : (
                            <>
                                <Col md={8}>
                                    <h6 className='text-dark pt-1 fw-6'>
                                            To Be Paid On : <span className='ms-2 border-bottom border-dark text-dark'>{data.painon}</span>
                                    </h6>
                                </Col>
                                <Col md={4}>
                                    <h6 className='text-dark pt-1 fw-6'>
                                            Paid On Date : <span className='ms-2 border-bottom border-dark text-dark'>{data.painondate}</span>
                                    </h6>
                                </Col>
                            </>
                        )
                    )}

                    <Col md={12}>
                        <h6 className='text-dark pt-1 fw-6'>Comment If Any : <span className='ms-2  text-dark'>{getFormattedComments(data)}</span></h6>
                    </Col>
                    {/* //--------------------------------------------// */}
                    {/* <Col md={12}>
                       
                    </Col> */}
                    <Col md={12} className='d-flex justify-content-between align-items-center'>
                        <div>
                        <div className='rounded-4 border border-1 border-dark p-1'>
                            <h6 className='text-dark pt-1 fw-6'>AMOUNT Rs : <span className='ms-2  text-dark'>{data.amount2 ? data.amount2 : data.amount} /-</span></h6>
                        </div>
                            <h6 className='text-dark pt-1 fw-6'>The Sum Of Rupees : <span className='ms-2  text-dark'>{amountInWords} Only</span></h6>
                        </div>
                        <img src={stamp} alt="image" height={110} id="invoiceImage" className='me-5' />
                    </Col>
                    {/* <Col md={5}>
                        <div className='rounded-4 border border-1 border-dark p-1'>
                            <h6 className='text-dark pt-1 fw-6'>AMOUNT Rs : <span className='ms-2 border-bottom border-dark text-dark'>{data.amount2 ? data.amount2:data.amount}</span></h6>
                        </div>
                    </Col>
                    <Col md={2}>

                    </Col>
                    <Col md={2}>

                    </Col>
                    <Col md={3}>

                        <img src={stamp} alt="image" height={110} id="invoiceImage" className='me-5' />

                    </Col> */}

                    <Col md={12} className='border-top border-1 border-dark my-2'>
                        <div className='ps-1 py-2'>
                            <h6 className={`text-dark mb-1 fw-6 fs-small  ${displayterm1}`}>
                                Myself {data.name} state that I am taking the services of M/S SUNDLASS  for Immigrant visa to US under full senses with the contents as per this receipt and -<br/>
                                1.	That, I accept the responsibility of the company is to contest my application in the respective offices of USCIS/NVC.<br />
                                2.	That, I entered into separate contract on stamp paper purchased by me for the proper services to be provided to me with the duration in addition to all the terms & conditions as mentioned in this receipt<br />
                                3.	That, I am never deported from any country nor am banned in any country for any type of illegal activity.<br />
                                4.	That, in case later it is found that I am lying with the company for the contents as in para 3, my payment will be forfeited and I will be liable to make the balance payment, if any as per the agreement.<br />
                                Signed at & within jurisdiction of Chandigarh & all the territorial jurisdiction within the Chandigarh.

                            </h6> 
                            <h6 className={`text-dark mb-1 fw-6 fs-small-sm ${displayterm2}`}>
                                Myself {data.name} state that I am taking the services of M/S SUNDLASS  for Immigrant visa to US under full senses with the contents as per this receipt and -<br/>
                                1.	That, I accept the responsibility of the company is to file four universities in USA for Bachelor’s/Master’s/Doctorate/Post Doctorate of my choice & I have approved the same on personal Email.<br />
                                2.	That, I understand M/S Sundlass is service provider and in now way a recruiter, job provider.<br />
                                3.	That, M/S Sundlass in no way is giving me any type of guarantee for success of visa OR influencing the Embassy/High Commission in getting visa issued.<br />
                                4.	That, I have paid M/S Sundlass the payment as mentioned in the receipt & neither before this I have made any payment.<br />
                                5.	That, in future if I make any payment as agreed OR pending OR for additional services, It’s my responsibility to procure proper receipt.<br />
                                6.	That, all Govt. Taxes are to be paid as per the existing laws.<br />
                                7.	That, any time period (if), given to me will start from the date I provide all the required documents as advised to me.<br />
                                8.	That, the payment paid to M/S Sundlass is for the services of filing of four universities, preparing of LOR’s, SOP & filing of scholarships if available to me as per my academics, IELTS/TOEFL/PTE/Duo-Lingo clubbed with my SAT/GRE scores.<br />
                                9.	That, in case I am providing ay wrong, fake, fraudulent information about my passport, deportation, ban from any country & later the facts comes to light, my payment will be forfeited & I am liable to make the balance payment, if any immediately to the company.<br />
                                10.	That, in case of deferral I accept to pay all the applicable charges as per M/S Sundlass rules & regulations.<br />
                                11.	That, I accept to pay all the fee to University, Embassy & will provide the international Credit/Debit card for such fees.<br />
                                12.	That, service charges paid by me are non-refundable under any circunstances.<br />
                                Signed at & within jurisdiction of Chandigarh & all the territorial jurisdiction within the Chandigarh.<br />

                            </h6>
                            <h6 className={`text-dark mb-1 fw-6 fs-small  ${displayterm3}`}>
                                Myself {data.name} state that I am taking the services of M/S SUNDLASS  under full senses with the contents as per this receipt and -<br/>
                                1.	That, I am fully responsible for all the documents related to my finances, education, job, income, income tax, property and more, if any.<br />
                                2.	That, I understand that M/S Sundlass is only visa counseling services & in no way they can influence the Embassy for issuance of visa.<br />
                                3.	That, I have made payment to M/S Sundlass as per the above contents of receipt and in no way I have made any payment before this; in future if I make any payment it’s my responsibility to procure the receipt from the company.<br />
                                4.	That, in case I am providing ay wrong, fake, fraudulent information about my passport, deportation, ban from any country & later the facts comes to light, my payment will be forfeited & I am liable to make the balance payment, if any immediately to the company.<br />
                                5.	That, service charges are non-refundable & cost of any package, if any to be paid additionally by me.<br />
                                6.	That, the fee of Embassy, cost of Hotels, Air-Tickets, Local Touring, Air port Transfers to be paid by me & in case of any cancellations, charges may apply.<br />
                                Signed at & within jurisdiction of Chandigarh & all the territorial jurisdiction of the Hon’ble Courts; within the Chandigarh.<br />

                            </h6>

                        </div>
                        <div className="container-fluid d-flex justify-content-center mt-2">
                            <div className="text-center w-75">
                                <h6 className='text-dark pt-1 fw-6'>
                                    ਉਪਰ ਲਿਖਿਆ ਸਾਰਿਆ ਸ਼ਰਤਾ ਦਾ ਪੰਜਾਬੀ ਵਿਚ ਅਨੁਵਾਦ ਕਰ ਕੇ ਮੈਨੂੰ ਗਵਾਹ ਦੇ ਸਾਮਣੇ ਸਮਝੀਆਂ ਗਈਆ
                                    ਅਤੇ ਮੈਂ ਇਹਨਾ ਨਾਲ ਸਹਿਮਤ ਹਾਂ । ਅਤੇ ਮੈਂ ਗਵਾਹ ਦੇ ਸਾਮਣੇ ਦਸਤਖਤ ਰਿਹਾ/ਰਹੀ ਹਾਂ |
                                </h6>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <h6 className='text-dark pt-2 fw-6'>Signature Of Customer : <span className='ms-2 border-bottom border-dark text-dark'> </span></h6>
                    </Col>
                    <Col md={6}>
                        <h6 className='text-dark pt-2 fw-6'>Signature Of Witness : <span className='ms-2 border-bottom border-dark text-dark'></span></h6>
                    </Col>
                    <Col md={6}>
                        <h6 className='text-dark pt-2 fw-6'>Name : <span className='ms-2 border-bottom border-dark text-dark'> </span></h6>
                    </Col>
                    <Col md={6}>
                        <h6 className='text-dark pt-2 fw-6'>Name  : <span className='ms-2 border-bottom border-dark text-dark'></span></h6>
                    </Col>
                    <Col md={6}>
                        <h6 className='text-dark pt-2 fw-6 my-2'>Address Of Applicant : <span className='ms-2  text-dark'>{data.address}</span></h6>
                    </Col>
                    <Col md={6}>
                        <h6 className='text-dark pt-2 fw-6 my-2'>Address Of Witness : <span className='ms-2  text-dark'></span></h6>
                    </Col>
                </Row>

            </Container>
        </div>
    );
});


export default Invoice2
